<script setup>
defineProps({
  modelValue: String,
  items: {
    type: Array,
    required: true,
  },
});
defineEmits(["update:modelValue", "input"]);
</script>
<template>
  <div class="autocomplete">
    <input
      :id="hiddenId"
      :value="modelValue"
      type="hidden"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <input
      v-model="search"
      type="text"
      @input="onChange"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter.prevent="onEnter"
    />
    <ul v-show="isOpen" id="autocomplete-results" class="autocomplete-results">
      <li
        v-for="(result, i) in results"
        :key="i"
        :class="{ 'is-active': i === arrowCounter }"
        class="autocomplete-result"
        @click="setResult(result)"
        @keyup.enter="setResult(result)"
      >
        <MemberTypeAheadResult :result="result"></MemberTypeAheadResult>
      </li>
    </ul>
  </div>
</template>

<script>
import MemberTypeAheadResult from "@/components/MemberTypeAheadResult.vue";
import { watch } from "vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "SearchAutocomplete",
  components: { MemberTypeAheadResult },
  data() {
    return {
      isOpen: false,
      results: [],
      search: "",
      arrowCounter: -1,
      isValid: false,
      hiddenId: uuidv4(),
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    watch(
      () => this.modelValue,
      (current) => {
        if (null === current) this.search = null;
      }
    );
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    updateBinding(id) {
      let el = document.getElementById(this.hiddenId);
      el.value = id;
      el.dispatchEvent(new Event("input"));
    },
    setResult(result) {
      if (result) {
        this.search = result.name;
        this.isValid = true;
        this.updateBinding(result.id);
      } else {
        this.isValid = false;
      }
      this.isOpen = false;
      this.arrowCounter = -1;
    },
    filterResults() {
      function filterSingle(haystack, needle) {
        return haystack.toLowerCase().indexOf(needle) > -1;
      }

      function filterContacts(contacts, needle) {
        let x = false;
        for (const contact of contacts) {
          x = x || filterSingle(contact, needle);
        }
        return x;
      }

      this.results = this.items.filter((item) => {
        return (
          filterSingle(item.name, this.search.toLowerCase()) ||
          filterContacts(item.emails, this.search.toLowerCase()) ||
          filterContacts(item.phones, this.search.toLowerCase())
        );
      });
    },
    onChange() {
      this.$emit("input", this.search);
      this.filterResults();
      this.isOpen = true;
      this.isValid = false;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        if (!this.isValid) {
          this.search = null;
        }
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      this.setResult(this.results[this.arrowCounter]);
    },
  },
};
</script>

<style>
.autocomplete {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  left: 0;
  right: 0;
  top: 32px;
  z-index: 1000;
  background: white;

  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  max-height: 400px;
  overflow-y: scroll;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
  border-bottom: solid 1px rgba(74, 174, 155, 0.09);
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
</style>
