import { commonFetch, ENDPOINTS, METHODS } from "@/services/Caller";

export function postPaybillValidations(payload) {
  return common(ENDPOINTS.PAYBILL_PENDING_VALIDATION, payload);
}

export function postGenerateReceipts(payload) {
  return common(ENDPOINTS.RECEIPTS_GENERATE, payload);
}

export function postSendReceipts(payload) {
  return common(ENDPOINTS.RECEIPTS_SEND, payload);
}

export function postPaybillReconciliationBulk(payload) {
  return common(ENDPOINTS.PAYBILL_RECON_BULK, payload);
}

export function postPaybillReconciliationPayment(payload) {
  return common(ENDPOINTS.PAYBILL_RECON_PAYMENT, payload);
}

function common(endpoint, payload) {
  return commonFetch(METHODS.POST, endpoint, payload);
}
