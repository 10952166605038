<script setup>
defineProps({
  links: {
    type: Object,
    required: true,
  },
  active: {
    type: String,
    required: true,
  },
});
</script>
<template>
  <div id="sidebar" :class="isOpen ? 'sidebar-open' : 'sidebar-closed'">
    <router-link
      v-for="link in links"
      :key="link.href"
      :class="{ 'sidebar-link-active': link.title === active }"
      :to="link.href"
      class="sidebar-link"
    >
      &nbsp;&raquo;&nbsp;{{ link.title }}
    </router-link>
    <img
      :class="isOpen ? 'icon-open' : 'icon-closed'"
      alt="toggle sidebar"
      class="circular-image prevent-select"
      src="/img/icons/expand.png"
      @click="toggleView"
    />
  </div>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleView() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
#sidebar {
  position: fixed;
  z-index: 1000;
  width: 200px;
  height: calc(100vh - var(--nav-height) - var(--nav-height));
  top: var(--nav-height);
  padding: 10px;
  background-color: #fff;
  color: rgb(86, 91, 94);
  transition: left 0.5s ease-out;
}

.sidebar-open {
  left: 0;
}

.sidebar-closed {
  left: -190px;
}

.sidebar-link {
  display: block;
  padding: 2px 0;
}

.sidebar-link-active {
  padding-left: 3px;
  color: var(--color-light50);
}

.circular-image {
  position: absolute;
  top: 50px;
  right: -10px;
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  cursor: pointer;
  transition: all 0.5s ease-out;
  padding: 4px;
}

.icon-open {
  transform: rotateZ(90deg);
}

.icon-closed {
  transform: rotateZ(-90deg);
}
</style>
