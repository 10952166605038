import { getToken } from "@/services/AuthService";
import { ref } from "vue";

export const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
export const BACKEND_URL_WS = process.env.VUE_APP_BACKEND_WS_URL;

export const IS_POSTING = ref(false);

export const ENDPOINTS = {
  LOGIN: "auth/authenticate",
  DEPARTMENTS: "treasury/department",
  CATEGORIES: "treasury/contribution",
  CONTRIBUTIONS: "treasury/payment",
  EXPENDITURES: "treasury/expenditure",
  MEMBERS_FETCH: "member/fetch",
  MEMBERS_CREATE: "member/create",
  USERS: "auth/fetch",
  PAYBILL_PENDING_VALIDATION: "treasury/paybill/validations",
  RECEIPTS_GENERATE: "treasury/receipts/generate",
  RECEIPTS_SEND: "treasury/receipts/send/email",
  PAYBILL_RECON_BULK: "treasury/paybill/reconciliation/bulk",
  PAYBILL_RECON_PAYMENT: "treasury/paybill/reconciliation/payment",
};

export const METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export function commonFetch(
  method,
  endpoint,
  payload = null,
  basicAuth = null,
  noAuth = false
) {
  IS_POSTING.value = true;

  let body = null;
  const headers = new Headers();
  if (method === METHODS.PUT || method === METHODS.POST) {
    headers.append("Content-Type", "application/json");
    body = JSON.stringify(payload);
  }

  if (!noAuth) {
    if (basicAuth) {
      headers.append("Authorization", `Basic ${basicAuth}`);
    } else {
      headers.append("Authorization", `Bearer ${getToken()}`);
    }
  }

  return fetch(`${BACKEND_URL}/${endpoint}`, {
    method: method,
    headers: headers,
    body: body,
  })
    .then(async (response) => {
      if (!response.ok) {
        let data;
        try {
          data = await response.json();
        } catch (e) {
          throw new Error(response.statusText);
        }
        throw new Error(data.responseDesc);
      }
      return response.json();
    })
    .finally(() => (IS_POSTING.value = false));
}
