<script setup>
import TreasurySideBar from "@/components/treasury/TreasurySidebar.vue";
import LinksReport from "@/components/treasury/links/LinksReport.vue";
</script>

<template>
  <TreasurySideBar :active="'Links'"></TreasurySideBar>
  <LinksReport></LinksReport>
</template>

<script>
export default {
  name: "LinksPage",
};
</script>
