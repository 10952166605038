import { commonFetch, ENDPOINTS, METHODS } from "@/services/Caller";

export function deleteMember(payload) {
  return common(ENDPOINTS.MEMBERS_FETCH, payload);
}

export function deleteDepartment(payload) {
  return common(ENDPOINTS.DEPARTMENTS, payload);
}

export function deleteCategory(payload) {
  return common(ENDPOINTS.CATEGORIES, payload);
}

export function deleteContribution(payload) {
  return common(ENDPOINTS.CONTRIBUTIONS + "/" + payload, null);
}

export function deleteExpenditure(payload) {
  return common(ENDPOINTS.EXPENDITURES, payload);
}

function common(endpoint, payload) {
  return commonFetch(METHODS.DELETE, endpoint, payload);
}
