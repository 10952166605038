<script setup>
import { FETCHED } from "@/services/PersistenceService";
import SearchAutocomplete from "@/components/SearchAutocomplete.vue";
import UserActions from "@/components/UserActions.vue";
import { IS_POSTING } from "@/services/Caller";
import GenerateReceiptsButton from "@/components/treasury/contributions/GenerateReceiptsButton.vue";
import SendReceiptsButton from "@/components/treasury/contributions/SendReceiptsButton.vue";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Track Contributions">
      <fieldset :disabled="IS_POSTING">
        <div class="filters">
          <label
            >Department
            <select v-model="params.departmentId" name="department">
              <option
                v-for="department in FETCHED.departments"
                :key="department.id"
                :value="department.id"
              >
                {{ department.name }}
              </option>
            </select>
          </label>
          <label
            >Category
            <select v-model="params.categoryId" name="category">
              <option
                v-for="category in FETCHED.categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
          </label>
          <label
            >Contributor
            <SearchAutocomplete
              v-model.trim="params.memberId"
              :items="FETCHED.members"
            ></SearchAutocomplete>
          </label>
          <label
            >Treasurer
            <select v-model="params.creatorId" name="treasurer">
              <option
                v-for="user in FETCHED.users"
                :key="user.id"
                :value="user.id"
              >
                {{ user.name }}
              </option>
            </select>
          </label>
          <label
            >From
            <input
              v-model.trim="params.fromDateCreated"
              name="from"
              type="datetime-local"
            />
          </label>
          <label
            >To
            <input
              v-model.trim="params.toDateCreated"
              name="to"
              type="datetime-local"
            />
          </label>
        </div>
        <div class="inline button-group">
          <button
            class="reset-button"
            type="button"
            @click.prevent="resetForm()"
          >
            Reset
          </button>
          <button
            class="submit-button"
            type="button"
            @click.prevent="queryData()"
          >
            Query
          </button>
        </div>
      </fieldset>
      <div v-if="entries.length" class="table-holder">
        <table id="report_table">
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Category</th>
              <th>Contributor</th>
              <th>Treasurer</th>
              <th>Amount</th>
              <th v-if="canEdit || canDelete">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(entry, index) in entries" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ entry.date_created }}</td>
              <td>{{ entry.contribution }}</td>
              <td>{{ entry.member }}</td>
              <td>{{ entry.treasurer }}</td>
              <td>{{ entry.amount }}</td>
              <td v-if="canEdit || canDelete">
                <UserActions
                  :can-delete="canDelete"
                  :can-edit="false"
                  @delete="deleteEntry(entry)"
                ></UserActions>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Total</td>
              <td>{{ total }}</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-if="entries.length" class="table-holder">
        <generate-receipts-button
          :end-date="params.toDateCreated ?? null"
          :start-date="params.fromDateCreated ?? null"
        />
        <send-receipts-button
          :end-date="params.toDateCreated ?? null"
          :start-date="params.fromDateCreated ?? null"
        />
      </div>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { fetchContributions } from "@/services/FetchService";
import {
  isDeletingTreasurer,
  isEditingTreasurer,
} from "@/services/AuthService";
import { deleteContribution } from "@/services/DeleteService";

export default {
  name: "ContributionReport",
  data() {
    return {
      canEdit: isEditingTreasurer(),
      canDelete: isDeletingTreasurer(),
      params: {
        departmentId: null,
        memberId: null,
        categoryId: null,
        creatorId: null,
        fromDateCreated: null,
        toDateCreated: null,
      },
      entries: [],
      total: null,
    };
  },
  methods: {
    queryData() {
      fetchContributions({
        department_id: this.params.departmentId,
        member_id: this.params.memberId,
        contribution_id: this.params.categoryId,
        treasurer_id: this.params.creatorId,
        from: this.params.fromDateCreated,
        to: this.params.toDateCreated,
        status: "0",
      })
        .then((x) => {
          notifySuccess("Fetch operation", "Contributions have been fetched");

          const currencyFormatter = new Intl.NumberFormat("en-KE", {
            style: "currency",
            currency: "KES",
          });
          const sum = x.reduce((result, entry) => result + entry.amount, 0);
          this.entries = x.map((entry) => {
            entry.amount = currencyFormatter.format(entry.amount);
            return entry;
          });
          this.total = currencyFormatter.format(sum);
        })
        .catch((error) => notifyError("Fetch operation", error));
    },
    resetForm() {
      this.entries = [];
      this.params = {
        departmentId: null,
        memberId: null,
        categoryId: null,
        creatorId: null,
        fromDateCreated: null,
        toDateCreated: null,
      };
    },
    deleteEntry(entry) {
      const OPERATION = "Deletion operation";

      const prompt = `Delete [${entry.member} - ${entry.contribution} - ${entry.amount}]?`;
      if (confirm(prompt)) {
        deleteContribution(entry.id)
          .then(() => {
            notifySuccess(OPERATION, "Payment has been deleted");
            this.removeEntry(entry.id);
          })
          .catch((error) => notifyError(OPERATION, error));
      }
    },
    removeEntry(id) {
      const foundAt = this.entries.findIndex((x) => x.id === id);
      if (foundAt !== -1) {
        this.entries.splice(foundAt, 1);
      }
    },
  },
};
</script>
