<script setup>
import UserActions from "@/components/UserActions.vue";
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Track Categories">
      <fieldset :disabled="IS_POSTING"></fieldset>
      <div v-if="entries.length" class="table-holder">
        <table id="report_table">
          <thead>
            <tr>
              <th>#</th>
              <th>Category</th>
              <th>Department</th>
              <th v-if="canEdit || canDelete">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(entry, index) in entries" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ entry.name }}</td>
              <td>{{ entry.department }}</td>
              <td v-if="canEdit || canDelete">
                <UserActions
                  :can-delete="canDelete"
                  :can-edit="canEdit"
                ></UserActions>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { FETCHED } from "@/services/PersistenceService";

export default {
  name: "CategoriesReport",
  data() {
    return {
      canEdit: false,
      canDelete: false,
      entries: FETCHED.categories,
    };
  },
};
</script>
