<script setup>
import { IS_POSTING } from "@/services/Caller";
</script>

<template>
  <form class="form">
    <fieldset :disabled="IS_POSTING || isLoading">
      <div class="form-input-holder">
        <input
          v-model.trim="username"
          class="form-input"
          name="username"
          placeholder="User name"
          type="text"
        />
      </div>
      <div class="form-input-holder">
        <input
          v-model.trim="password"
          class="form-input"
          name="password"
          placeholder="Password"
          type="password"
        />
      </div>
      <div class="form-input-holder">
        <button
          class="submit-button"
          type="button"
          @click.prevent="authenticate"
        >
          Sign in
        </button>
      </div>
    </fieldset>
  </form>
</template>

<script>
import { authUser } from "@/services/AuthService";
import { loadAll } from "@/services/FetchService";
import { notifyError, notifySuccess } from "@/services/NotificationService";

export default {
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: "",
      isLoading: false,
    };
  },
  methods: {
    authenticate() {
      const OPERATION = "Login operation";
      if (!this.username) {
        notifyError(OPERATION, "Invalid username");
        return;
      }
      if (!this.password) {
        notifyError(OPERATION, "Invalid password");
        return;
      }
      authUser(this.username, this.password)
        .then(() => {
          this.isLoading = true;
          loadAll()
            .then(() => {
              notifySuccess(OPERATION, "Welcome");
              this.isLoading = false;
              this.$router.push(this.$route.query.redirect || "/");
            })
            .catch((error) => {
              this.isLoading = false;
              return notifyError(OPERATION, error);
            });
        })
        .catch((error) => {
          console.log(error);
          notifyError(OPERATION, "Unable to log in");
        });
    },
  },
};
</script>
