export function sanityChecks(target) {
  const e = (error) => ({
    hasError: true,
    errorMessage: error,
    file: null,
  });

  if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
    return e("The File APIs are not fully supported in this browser.");
  }

  if (!(target && target.files)) {
    return e("Invalid target");
  }

  if (!target.files) {
    return e(
      "This browser doesn't seem to support the `files` property of file inputs."
    );
  }

  if (!target.files[0]) {
    return e("No file selected.");
  }

  const file = target.files[0];
  const sizeInMBs = file.size / (1024 * 1024);
  if (sizeInMBs > 20) {
    return e("File is too large to be processed. Must be less than 20MB");
  }

  return {
    hasError: false,
    errorMessage: null,
    file: file,
  };
}
