<script setup>
defineProps({
  title: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <div class="collapsible-wrapper">
    <div class="collapsible-title">
      <div class="header">
        <h4>{{ title }}</h4>
      </div>
      <label
        :class="
          isChecked
            ? 'collapsible-toggle-label-collapse'
            : 'collapsible-toggle-label-expand'
        "
        :for="id"
      ></label>
    </div>
    <input
      :id="id"
      v-model="isChecked"
      class="collapsible-toggle"
      type="checkbox"
    />
    <div class="collapsible-content">
      <div class="content-inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  name: "CollapsibleWrapper",
  data() {
    return {
      id: uuidv4(),
      isChecked: false,
    };
  },
};
</script>

<style scoped>
.collapsible-toggle {
  display: none;
}

.collapsible-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapsible-toggle-label-expand::before,
.collapsible-toggle-label-collapse::before {
  content: " ";
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  vertical-align: middle;
  margin-right: 0.7rem;
  transition: transform 0.2s ease-out;
}

.collapsible-toggle-label-expand::before {
  transform: rotate(90deg) translateX(0px);
}

.collapsible-toggle-label-collapse::before {
  transform: rotate(-90deg) translateX(0px);
}

.collapsible-content {
  display: none;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.collapsible-toggle:checked + .collapsible-content {
  display: block;
}
</style>
