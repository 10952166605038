<script setup>
import ContributionForm from "@/components/treasury/contributions/ContributionForm.vue";
import ContributionReport from "@/components/treasury/contributions/ContributionReport.vue";
import TreasurySideBar from "@/components/treasury/TreasurySidebar.vue";
</script>

<template>
  <TreasurySideBar :active="'Contributions'"></TreasurySideBar>
  <ContributionForm v-if="showForm"></ContributionForm>
  <ContributionReport
    :class="showForm ? 'subsequent-form' : ''"
  ></ContributionReport>
</template>

<script>
import { isCreationTreasurer } from "@/services/AuthService";

export default {
  name: "ContributionPage",
  data() {
    return {
      showForm: isCreationTreasurer(),
    };
  },
};
</script>

<style scoped>
.subsequent-form {
  margin-top: 1rem;
}
</style>
