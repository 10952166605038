<script setup>
import SearchAutocomplete from "@/components/SearchAutocomplete.vue";
import { FETCHED } from "@/services/PersistenceService";
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Add a Contribution">
      <fieldset :disabled="IS_POSTING">
        <div class="common">
          <label class="form-input-holder"
            >Contributor
            <SearchAutocomplete
              v-model.trim="contributorId"
              :items="FETCHED.members"
            ></SearchAutocomplete>
          </label>
        </div>
        <div id="entries">
          <div v-for="entry in entries" :key="entry.id" class="entry">
            <button class="index" disabled type="button">
              {{ entry.index }}
            </button>
            <button
              class="remove"
              type="button"
              @click.prevent="removeForm(entry.id)"
            >
              &nbsp;&times;&nbsp;
            </button>
            <div class="inline">
              <label
                >Category
                <select v-model="entry.categoryId" name="category">
                  <option
                    v-for="category in FETCHED.categories"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </label>
              <label
                >Amount
                <input
                  v-model.trim="entry.amount"
                  min="0"
                  name="amount"
                  type="number"
                />
              </label>
            </div>
            <label class="form-input-holder"
              >Description
              <input
                v-model.trim="entry.description"
                name="description"
                type="text"
              />
            </label>
          </div>
        </div>
        <div class="inline button-group">
          <button
            class="neutral-button"
            type="button"
            @click.prevent="newForm()"
          >
            Add
          </button>
          <button
            class="reset-button"
            type="button"
            @click.prevent="resetForm()"
          >
            Reset
          </button>
          <button class="submit-button" type="button" @click.prevent="post()">
            Submit
          </button>
        </div>
      </fieldset>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { getAuth } from "@/services/PersistenceService";
import { putContribution } from "@/services/PutService";

export default {
  name: "ContributionForm",
  data() {
    return {
      entries: [],
      contributorId: null,
    };
  },
  mounted() {
    this.newForm();
  },
  methods: {
    newForm() {
      this.entries.push({
        id: uuidv4(),
        index: null,
        categoryId: null,
        amount: null,
        description: null,
      });
      this.resetIds();
    },
    resetForm() {
      this.entries = [];
      this.contributorId = null;
      this.newForm();
    },
    post() {
      const OPERATION = "Creation operation";

      if (!this.contributorId) {
        notifyError(OPERATION, "Invalid contributor");
        return;
      }
      for (let i = 0; i < this.entries.length; i++) {
        const entry = this.entries[i];
        if (!entry.categoryId || !entry.amount) {
          notifyError(
            OPERATION,
            "Entry " + (i + 1) + " is not fully populated"
          );
          return;
        }

        const payload = {
          contribution_id: entry.categoryId,
          amount: entry.amount,
          description: entry.description ?? "New Payment",
          member_id: this.contributorId,
          treasurer_id: getAuth().id,
          campus_id: "1",
        };

        putContribution(payload)
          .then((data) => {
            if (data.status) {
              notifySuccess(OPERATION, data.message);
              this.removeForm(entry.id);
            } else {
              notifyError(OPERATION, data.message);
            }
          })
          .catch((error) => notifyError(OPERATION, error));
      }
    },
    removeForm(id) {
      const foundAt = this.entries.findIndex((x) => x.id === id);
      if (foundAt !== -1) {
        this.entries.splice(foundAt, 1);
      }
      if (this.entries.length === 0) {
        this.resetForm();
      }
      this.resetIds();
    },
    resetIds() {
      let i = 1;
      for (const entry of this.entries) {
        entry.index = i++;
      }
    },
  },
};
</script>
