<script setup>
defineProps({
  result: {
    type: Object,
    required: true,
  },
});
</script>

<template>
  <div class="wrapper">
    <div class="name">{{ result.name }}</div>
    <div class="email">{{ concatContacts(result.emails) }}</div>
    <div class="phone">{{ concatContacts(result.phones) }}</div>
  </div>
</template>

<script>
export default {
  name: "MemberTypeAheadResult",
  methods: {
    concatContacts(contacts) {
      return contacts ? contacts.join(" \u2022 ") : "";
    },
  },
};
</script>
<style scoped>
.wrapper {
  padding: 10px;
}

.name {
  font-weight: bold;
}

.email,
.phone {
  font-size: x-small;
  font-weight: normal;
}
</style>
