<script setup>
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Add an Expense">
      <fieldset :disabled="IS_POSTING">
        <div class="inline">
          <label
            >Department
            <select
              v-model="departmentId"
              name="department"
              @change="filterCategories($event.target.value)"
            >
              <option
                v-for="department in FETCHED.departments"
                :key="department.id"
                :value="department.id"
              >
                {{ department.name }}
              </option>
            </select>
          </label>
          <label
            >Category
            <select v-model="categoryId" name="category">
              <option
                v-for="category in departmentCategories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
          </label>
          <label
            >Source
            <select v-model="source" name="source">
              <option value="dependent">Church</option>
              <option value="independent">Self</option>
            </select>
          </label>
        </div>
        <div class="inline">
          <label
            >Recipient
            <input v-model.trim="recipient" name="recipient" type="text" />
          </label>
          <label
            >Purpose
            <input v-model.trim="purpose" name="purpose" type="text" />
          </label>
        </div>
        <div class="inline">
          <label
            >Amount
            <input v-model.trim="amount" min="0" name="amount" type="number" />
          </label>
          <label
            >Date disbursed
            <input
              v-model.trim="dateDisbursed"
              name="date_disbursed"
              type="date"
            />
          </label>
        </div>
        <div class="inline button-group">
          <button
            class="reset-button"
            type="button"
            @click.prevent="resetForm()"
          >
            Reset
          </button>
          <button class="submit-button" type="button" @click.prevent="post()">
            Submit
          </button>
        </div>
      </fieldset>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { FETCHED, getAuth } from "@/services/PersistenceService";
import { putExpenditure } from "@/services/PutService";
import { ref } from "vue";

export default {
  name: "ExpenditureForm",
  data() {
    return {
      recipient: null,
      departmentId: null,
      categoryId: null,
      purpose: null,
      source: null,
      amount: null,
      dateDisbursed: null,
      departmentCategories: ref([]),
      allCategories: FETCHED.categories,
    };
  },
  methods: {
    resetForm() {
      this.recipient = null;
      this.departmentId = null;
      this.categoryId = null;
      this.purpose = null;
      this.source = null;
      this.amount = null;
      this.dateDisbursed = null;
    },
    filterCategories(departmentId) {
      this.departmentCategories = this.allCategories.filter(
        (x) =>
          Number.parseInt(x.department_id) === Number.parseInt(departmentId)
      );
    },
    post() {
      const OPERATION = "Creation operation";

      if (!this.recipient) {
        notifyError(OPERATION, "Invalid recipient");
        return;
      }

      if (!this.departmentId) {
        notifyError(OPERATION, "Invalid department");
        return;
      }

      if (!this.categoryId) {
        notifyError(OPERATION, "Invalid category");
        return;
      }

      if (!this.purpose) {
        notifyError(OPERATION, "Invalid purpose");
        return;
      }

      if (!this.source) {
        notifyError(OPERATION, "Invalid source");
        return;
      }

      if (!this.amount) {
        notifyError(OPERATION, "Invalid amount");
        return;
      }

      if (!this.dateDisbursed) {
        notifyError(OPERATION, "Invalid date disbursed");
        return;
      }

      const payload = {
        posted_by: getAuth().name,
        recipient: this.recipient,
        department_id: this.departmentId,
        contribution_id: this.categoryId,
        purpose: this.purpose,
        dependent_type: this.source,
        amount: this.amount,
        date_disbursed: this.dateDisbursed,
      };
      putExpenditure(payload)
        .then((data) => {
          if (data.status) {
            notifySuccess(OPERATION, data.message);
            this.resetForm();
          } else {
            notifyError(OPERATION, data.message);
          }
        })
        .catch((error) => notifyError(OPERATION, error));
    },
  },
};
</script>
