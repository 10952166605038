import { useNotification } from "@kyvg/vue3-notification";

const { notify } = useNotification();

export function notifyError(title, message) {
  notifyBase(title, message, "error");
}

export function notifyInfo(title, message) {
  notifyBase(title, message, "warn");
}

export function notifySuccess(title, message) {
  notifyBase(title, message, "success");
}

function notifyBase(title, message, type) {
  notify({
    title: title,
    text: message,
    type: type,
  });
}
