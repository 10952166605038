<script setup>
defineEmits(["processed"]);
</script>

<template>
  <input accept=".csv" type="file" @change="onFileChanged($event)" />
</template>

<script>
import { notifyError } from "@/services/NotificationService";
import { sanityChecks } from "@/services/CsvFileHandler";

export default {
  name: "CsvFileInput",
  methods: {
    onFileChanged($event) {
      this.inputElement = $event.target;
      const OPERATION = "Reconciliation operation";

      const checks = sanityChecks($event.target);
      if (checks.hasError) {
        notifyError(OPERATION, checks.errorMessage);
        return;
      }

      let fr = new FileReader();
      fr.onload = () => {
        this.$emit("processed", fr.result);
      };
      fr.readAsText(checks.file);
    },
  },
};
</script>
