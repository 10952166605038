<script setup>
import TreasurySideBar from "@/components/treasury/TreasurySidebar.vue";
import PendingTransactionsReport from "@/components/treasury/paybill/PendingTransactionsReport.vue";
import ReconciliationReport from "@/components/treasury/paybill/ReconciliationReport.vue";
</script>

<template>
  <TreasurySideBar :active="'Paybill'"></TreasurySideBar>
  <PendingTransactionsReport
    :class="showForm ? 'subsequent-form' : ''"
  ></PendingTransactionsReport>
  <ReconciliationReport
    :class="showForm ? 'subsequent-form' : ''"
  ></ReconciliationReport>
</template>

<script>
import { isCreationTreasurer } from "@/services/AuthService";

export default {
  name: "PaybillPage",
  data() {
    return {
      showForm: isCreationTreasurer(),
    };
  },
};
</script>

<style scoped>
.subsequent-form {
  margin-top: 1rem;
}
</style>
