<script setup>
import { AUTH } from "@/services/AuthService";

defineProps({
  church: {
    type: String,
    required: true,
  },
});
</script>
<template>
  <nav>
    <router-link to="/">{{ church }}</router-link>
    <ul>
      <li v-if="AUTH.isLoggedIn">
        <router-link to="/logout">Exit</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "TopBar",
};
</script>

<style scoped>
nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--nav-height);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 0 20px;
  transition: background-color 1s ease-in-out;
  background-color: var(--color-submit);
}

nav ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  margin: 0;
  padding: 0 10px;
}

nav a {
  line-height: var(--nav-height);
}
</style>
