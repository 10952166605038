<script setup>
import { IS_POSTING } from "@/services/Caller";
import CsvFileInput from "@/components/treasury/paybill/CsvFileInput.vue";
import UserActions from "@/components/UserActions.vue";
import { postPaybillReconciliationPayment } from "@/services/PostService";
import PopupForm from "@/components/treasury/paybill/PopupForm.vue";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
</script>

<template>
  <div>
    <form class="form">
      <collapsible-wrapper title="M-PESA Statement Reconciliation">
        <fieldset :disabled="IS_POSTING">
          <p>Notice: Remove the summary section</p>
          <label>
            Name
            <CsvFileInput @processed="fileProcessed" />
          </label>
          <div class="inline button-group">
            <button
              class="reset-button"
              type="button"
              @click.prevent="resetForm()"
            >
              Reset
            </button>
            <button
              :disabled="null === data"
              class="submit-button"
              type="button"
              @click.prevent="post()"
            >
              Submit
            </button>
          </div>
        </fieldset>
        <div v-if="rows.length" class="table-holder">
          <table id="report_table">
            <thead>
              <tr>
                <th>#</th>
                <th>Transaction ID</th>
                <th>Timestamp</th>
                <th>Amount</th>
                <th>Contribution</th>
                <th>Contributor</th>
                <th v-if="canEdit || canDelete">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in rows" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ row.receipt_no }}</td>
                <td>{{ row.completion_time }}</td>
                <td>{{ row.paid_in }}</td>
                <td>{{ row.acc_no }}</td>
                <td>{{ row.other_party_info }}</td>
                <td v-if="canEdit || canDelete">
                  <UserActions
                    :can-delete="canDelete"
                    :can-edit="canEdit"
                    @edit="editEntry(row)"
                  ></UserActions>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>{{ total }}</td>
                <td></td>
                <td></td>
                <td v-if="canEdit || canDelete"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </collapsible-wrapper>
    </form>

    <popup-form
      :close-popup="closePopup"
      :handle-posted="handlePosted"
      :modal-entry="modalEntry"
      :popup-trigger="popupTrigger"
      :post-function="postPaybillReconciliationPayment"
    ></popup-form>
  </div>
</template>

<script>
import { postPaybillReconciliationBulk } from "@/services/PostService";
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { ref } from "vue";

export default {
  name: "ReconciliationReport",
  data() {
    return {
      canEdit: true,
      canDelete: false,
      inputElement: null,
      data: null,
      rows: [],
      total: 0,
      modalEntry: {},
      popupTrigger: ref(false),
    };
  },
  methods: {
    resetForm() {
      if (this.inputElement) {
        this.inputElement.value = null;
      }
      this.data = null;
      this.rows = [];
      this.total = 0;
      this.modalEntry = {};
    },
    fileProcessed(data) {
      this.data = data;
    },
    post() {
      const OPERATION = "Reconciliation operation";

      if (this.data) {
        postPaybillReconciliationBulk({
          csvString: this.data,
        })
          .then((data) => {
            if (data.status) {
              notifySuccess(OPERATION, data.message);
              this.rows = data.data;
              this.rows.forEach((row) => {
                this.total += Number.parseInt(row.paid_in);
              });
            } else {
              notifyError(OPERATION, data.message);
            }
          })
          .catch((error) => {
            notifyError(OPERATION, error);
          });
      }
    },
    handlePosted(data) {
      this.removeEntry(data.id);
      this.closePopup();
    },
    openPopup() {
      this.popupTrigger = true;
    },
    closePopup() {
      this.popupTrigger = false;
    },
    editEntry(data) {
      this.modalEntry = {
        id: data.receipt_no,
        contributor: data.other_party_info,
        time: data.completion_time,
        amount: data.paid_in,
        accNo: data.acc_no,
      };
      this.openPopup();
    },
    removeEntry(id) {
      const foundAt = this.rows.findIndex((x) => x.id === id);
      if (foundAt !== -1) {
        this.rows.splice(foundAt, 1);
      }
    },
  },
};
</script>
