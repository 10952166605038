<template>
  <div class="modules">
    <router-link
      v-for="module in modules"
      v-show="module.show"
      :key="module.path"
      :to="module.path"
      class="module"
    >
      <div class="module-header">
        <p>{{ module.title }}</p>
        <img :alt="module.alt" :src="module.src" />
      </div>
      <div class="module-content">
        <p>{{ module.desc }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import {
  isAdministrator,
  isCommunication,
  isTreasurer,
} from "@/services/AuthService";

export default {
  name: "DashboardPage",
  data() {
    return {
      modules: [
        {
          title: "Treasury",
          path: "/treasury",
          src: "/img/icons/coins.png",
          alt: "treasury module icon",
          desc: "Manage contributions, expenditure, receipts and reports.",
          show: isTreasurer(),
        },
        {
          title: "Communication",
          path: "/communication",
          src: "/img/icons/speaker.png",
          alt: "communication module icon",
          desc: "Get in touch with members, quickly and reliably.",
          show: isCommunication(),
        },
        {
          title: "Management",
          path: "/management",
          src: "/img/icons/audit.png",
          alt: "management module icon",
          desc: "Manage system users and audit activities.",
          show: isAdministrator(),
        },
      ],
    };
  },
};
</script>

<style scoped>
.modules {
  display: grid;
  gap: 1rem;
}

@media (min-width: 40rem) {
  .modules {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}

.module {
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06), 0 2px 0 0 rgba(0, 0, 0, 0.02);
  text-align: start;
}

.module div {
  padding: 0.75rem 1rem;
}

.module-header {
  border-bottom: 0.1rem solid rgb(229 231 235);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.module-header p {
  text-transform: uppercase;
}

.module-header img {
  height: 48px;
  width: auto;
}

.module-content p {
  font-size: smaller;
}
</style>
