<script setup>
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Add a Member">
      <fieldset :disabled="IS_POSTING">
        <div class="inline">
          <label
            >Name
            <input v-model.trim="name" type="text" />
          </label>
          <label
            >Gender
            <select v-model="gender" name="gender">
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </label>
        </div>
        <div class="inline">
          <label
            >Phone number
            <input v-model.trim="phone" type="tel" />
          </label>
          <label
            >Email address
            <input v-model.trim="email" type="email" />
          </label>
        </div>
        <div class="inline button-group">
          <button
            class="reset-button"
            type="button"
            @click.prevent="resetForm()"
          >
            Reset
          </button>
          <button class="submit-button" type="button" @click.prevent="post()">
            Submit
          </button>
        </div>
      </fieldset>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { putMember } from "@/services/PutService";

export default {
  name: "MembersForm",
  data() {
    return {
      name: null,
      gender: null,
      phone: null,
      email: null,
    };
  },
  methods: {
    resetForm() {
      this.name = null;
      this.gender = null;
      this.phone = null;
      this.email = null;
    },
    post() {
      const OPERATION = "Creation operation";

      if (!this.name) {
        notifyError(OPERATION, "Invalid member name");
        return;
      }

      if (!this.gender) {
        notifyError(OPERATION, "Invalid gender");
        return;
      }

      const payload = {
        name: this.name,
        gender: this.gender,
        phone: this.phone,
        email: this.email,
        password: "12345",
        type: 1,
        campus_id: 1,
      };
      putMember(payload)
        .then((data) => {
          if (data.status) {
            notifySuccess(OPERATION, data.message);
            this.resetForm();
          } else {
            notifyError(OPERATION, data.message);
          }
        })
        .catch((error) => notifyError(OPERATION, error));
    },
  },
};
</script>
