<script setup>
import SearchAutocomplete from "@/components/SearchAutocomplete.vue";
import { FETCHED } from "@/services/PersistenceService";
import { IS_POSTING } from "@/services/Caller";

defineProps({
  validationEntry: {
    type: Object,
    required: true,
  },
  postFunction: {
    type: Function,
    required: true,
  },
});
defineEmits(["posted"]);
</script>

<template>
  <form class="form">
    <fieldset :disabled="IS_POSTING">
      <div class="common">
        <label class="form-input-holder"
          >Contributor
          <SearchAutocomplete
            v-model.trim="contributorId"
            :items="FETCHED.members"
          ></SearchAutocomplete>
        </label>
      </div>
      <div id="entries">
        <div v-for="entry in entries" :key="entry.id" class="entry">
          <button class="index" disabled type="button">
            {{ entry.index }}
          </button>
          <button
            class="remove"
            type="button"
            @click.prevent="removeForm(entry.id)"
          >
            &nbsp;&times;&nbsp;
          </button>
          <div class="inline">
            <label
              >Category
              <select v-model="entry.categoryId" name="category">
                <option
                  v-for="category in FETCHED.categories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </label>
            <label
              >Amount
              <input
                v-model.trim="entry.amount"
                min="0"
                name="amount"
                type="number"
              />
            </label>
          </div>
          <label class="form-input-holder"
            >Description
            <input
              v-model.trim="entry.description"
              name="description"
              type="text"
            />
          </label>
        </div>
      </div>
      <div class="inline button-group">
        <button class="neutral-button" type="button" @click.prevent="newForm()">
          Add
        </button>
        <button class="reset-button" type="button" @click.prevent="resetForm()">
          Reset
        </button>
        <button class="submit-button" type="button" @click.prevent="post()">
          Submit
        </button>
      </div>
    </fieldset>
  </form>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { getAuth } from "@/services/PersistenceService";

export default {
  name: "PendingTransactionsForm",
  data() {
    return {
      entries: [],
      contributorId: null,
    };
  },
  mounted() {
    this.newForm();
  },
  methods: {
    newForm() {
      this.entries.push({
        id: uuidv4(),
        index: null,
        categoryId: null,
        amount: null,
        description: "Via Paybill",
      });
      this.resetIds();
    },
    resetForm() {
      this.entries = [];
      this.contributorId = null;
      this.newForm();
    },
    post() {
      const OPERATION = "Creation operation";

      if (!this.contributorId) {
        notifyError(OPERATION, "Invalid contributor");
        return;
      }
      const payload = { trans_id: this.validationEntry.id, splits: [] };
      let sum = 0;
      for (let i = 0; i < this.entries.length; i++) {
        const entry = this.entries[i];
        if (!entry.categoryId || !entry.amount || !entry.description) {
          notifyError(
            OPERATION,
            "Entry " + (i + 1) + " is not fully populated"
          );
          return;
        }

        sum += Number.parseFloat(entry.amount);

        payload.splits.push({
          member_id: this.contributorId,
          campus_id: "1",
          treasurer_id: getAuth().id,
          contribution_id: entry.categoryId,
          amount: entry.amount,
          description: entry.description ?? "Via Paybill",
        });
      }

      const expectedSum = this.validationEntry.amount;
      const delta = Math.abs(sum - expectedSum);
      if (delta >= 1) {
        notifyError(
          OPERATION,
          `Sum of split amounts not within delta range of ${expectedSum}`
        );
        return;
      }
      this.postFunction(payload)
        .then((data) => {
          if (data.status) {
            notifySuccess(OPERATION, data.message);
            this.resetForm();
            this.$emit("posted", this.validationEntry);
          } else {
            notifyError(OPERATION, data.message);
          }
        })
        .catch((error) => notifyError(OPERATION, error));
    },
    removeForm(id) {
      const foundAt = this.entries.findIndex((x) => x.id === id);
      if (foundAt !== -1) {
        this.entries.splice(foundAt, 1);
      }
      if (this.entries.length === 0) {
        this.resetForm();
      }
      this.resetIds();
    },
    resetIds() {
      let i = 1;
      for (const entry of this.entries) {
        entry.index = i++;
      }
    },
  },
};
</script>
