<script setup>
defineProps({
  startDate: {
    type: String,
    required: false,
    default: null,
  },
  endDate: {
    type: String,
    required: false,
    default: null,
  },
});
</script>

<template>
  <button class="submit-button" type="button" @click="generateReceipts">
    Generate Receipts
  </button>
</template>

<script>
import { postGenerateReceipts } from "@/services/PostService";
import { notifyError, notifySuccess } from "@/services/NotificationService";

export default {
  name: "GenerateReceiptsButton",
  methods: {
    generateReceipts() {
      const OPERATION = "Post operation";

      if (confirm(this.generatePrompt())) {
        postGenerateReceipts({
          from: this.startDate,
          to: this.endDate,
        })
          .then((data) => {
            if (data.status) {
              notifySuccess(OPERATION, data.message);
            } else {
              notifyError(OPERATION, data.message);
            }
          })
          .catch((error) => notifyError(OPERATION, error));
      }
    },
    generatePrompt() {
      if (this.startDate) {
        if (this.endDate) {
          const starting = new Date(this.startDate).toLocaleString();
          const ending = new Date(this.endDate).toLocaleString();
          return `Generate receipts from ${starting} up to ${ending}`;
        } else {
          const starting = new Date(this.startDate).toLocaleString();
          return `Generate receipts from ${starting}`;
        }
      } else if (this.endDate) {
        const ending = new Date(this.endDate).toLocaleString();
        return `Generate receipts up to ${ending}`;
      } else {
        return "Generate all receipts";
      }
    },
  },
};
</script>
