import { commonFetch, ENDPOINTS, METHODS } from "@/services/Caller";

export function putMember(payload) {
  return common(ENDPOINTS.MEMBERS_CREATE, payload);
}

export function putDepartment(payload) {
  return common(ENDPOINTS.DEPARTMENTS, payload);
}

export function putCategory(payload) {
  return common(ENDPOINTS.CATEGORIES, payload);
}

export function putContribution(payload) {
  return common(ENDPOINTS.CONTRIBUTIONS, payload);
}

export function putExpenditure(payload) {
  return common(ENDPOINTS.EXPENDITURES, payload);
}

function common(endpoint, payload) {
  return commonFetch(METHODS.PUT, endpoint, payload);
}
