<script setup>
import ExpenditureForm from "@/components/treasury/expenditure/ExpenditureForm.vue";
import ExpenditureReport from "@/components/treasury/expenditure/ExpenditureReport.vue";
import TreasurySideBar from "@/components/treasury/TreasurySidebar.vue";
</script>

<template>
  <TreasurySideBar :active="'Expenditure'"></TreasurySideBar>
  <ExpenditureForm v-if="showForm"></ExpenditureForm>
  <ExpenditureReport
    :class="showForm ? 'subsequent-form' : ''"
  ></ExpenditureReport>
</template>

<script>
import { isCreationTreasurer } from "@/services/AuthService";

export default {
  name: "ExpenditurePage",
  data() {
    return {
      showForm: isCreationTreasurer(),
    };
  },
};
</script>

<style scoped>
.subsequent-form {
  margin-top: 1rem;
}
</style>
