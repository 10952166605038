<script setup>
import PendingTransactionsForm from "@/components/treasury/paybill/PendingTransactionsForm.vue";

defineProps({
  popupTrigger: {
    type: Boolean,
    required: true,
  },
  postFunction: {
    type: Function,
    required: true,
  },
  handlePosted: {
    type: Function,
    required: true,
  },
  modalEntry: {
    type: Object,
    required: true,
  },
  closePopup: {
    type: Function,
    required: true,
  },
});
</script>

<template>
  <div v-if="popupTrigger" class="modal">
    <div class="modal-inner">
      <button class="neutral-button" type="button" @click="closePopup">
        Close
      </button>
      <div class="details">{{ concatedDetails }}</div>
      <div class="modal-scrollable">
        <PendingTransactionsForm
          :post-function="postFunction"
          :validation-entry="modalEntry"
          @posted="handlePosted"
        ></PendingTransactionsForm>
      </div>
    </div>
  </div>
</template>
<script>
import { watch } from "vue";

export default {
  name: "PopupForm",
  data() {
    watch(
      () => this.modalEntry,
      (current) => {
        this.concatedDetails = current ? this.concatDetails(current) : "";
      }
    );
    return {
      concatedDetails: "",
    };
  },
  methods: {
    concatDetails(data) {
      if (data) {
        return `id=${data.id} | contributor=${data.contributor} | time=${data.time} | amount=${data.amount} | acc_no=${data.accNo}`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  .modal-inner {
    position: relative;
    background-color: rgba(255, 255, 255, 1);
    padding: 20px;

    .modal-scrollable {
      overflow-y: scroll;
      max-height: 50vh;
    }
  }
}

.details {
  font-size: 10px;
  text-align: left;
}
</style>
