<script setup>
import { FETCHED } from "@/services/PersistenceService";
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Add a Category">
      <fieldset :disabled="IS_POSTING">
        <div class="inline">
          <label
            >Department
            <select v-model="selectedDepartment" name="department">
              <option
                v-for="department in FETCHED.departments"
                :key="department.id"
                :value="department.id"
              >
                {{ department.name }}
              </option>
            </select>
          </label>
          <label class="form-input-holder"
            >Category
            <input v-model.trim="newCategory" name="name" type="text" />
          </label>
        </div>
        <div class="inline button-group">
          <button
            class="reset-button"
            type="button"
            @click.prevent="resetForm()"
          >
            Reset
          </button>
          <button class="submit-button" type="button" @click.prevent="post()">
            Submit
          </button>
        </div>
      </fieldset>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { putCategory } from "@/services/PutService";

export default {
  name: "CategoriesForm",
  data() {
    return {
      newCategory: null,
      selectedDepartment: null,
    };
  },
  methods: {
    resetForm() {
      this.newCategory = null;
      this.selectedDepartment = null;
    },
    post() {
      const OPERATION = "Creation operation";

      if (!this.newCategory) {
        notifyError(OPERATION, "Invalid category name");
        return;
      }
      if (!this.selectedDepartment) {
        notifyError(OPERATION, "Invalid department");
        return;
      }

      const payload = {
        contribution: this.newCategory,
        department_id: this.selectedDepartment,
      };
      putCategory(payload)
        .then((data) => {
          if (data.status) {
            notifySuccess(OPERATION, data.message);
            this.resetForm();
          } else {
            notifyError(OPERATION, data.message);
          }
        })
        .catch((error) => notifyError(OPERATION, error));
    },
  },
};
</script>
