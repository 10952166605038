<template>
  <notifications position="bottom right" />
  <router-view v-slot="{ Component }">
    <TopBar :church="church" />
    <div class="page-content">
      <component :is="Component" :key="$route.path" />
    </div>
    <BottomBar :church="church" :year="year" />
  </router-view>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import BottomBar from "@/components/BottomBar.vue";
import { reload } from "@/services/PersistenceService";

export default {
  name: "App",
  components: {
    TopBar,
    BottomBar,
  },
  data() {
    return {
      church: process.env.VUE_APP_CHURCH_NAME,
      year: 2017,
    };
  },
  mounted() {
    // initBroker();
    reload();
  },
};
</script>

<style scoped>
.page-content {
  font-weight: normal;
  margin: var(--nav-height) auto;
  padding: 2.5rem 1.75rem 3.5rem 1.75rem;
  position: relative;
}
</style>
