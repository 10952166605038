<script setup>
defineProps({
  canEdit: {
    type: Boolean,
    required: true,
  },
  canDelete: {
    type: Boolean,
    required: true,
  },
});
defineEmits(["edit", "delete"]);
</script>

<template>
  <div class="holder">
    <img
      v-if="canEdit"
      alt="edit entry"
      src="/img/icons/edit.png"
      @click="$emit('edit')"
    />
    <img
      v-if="canDelete"
      alt="delete entry"
      src="/img/icons/delete.png"
      @click="$emit('delete')"
    />
  </div>
</template>

<script>
export default {
  name: "UserActions",
};
</script>
<style scoped>
.holder {
  display: flex;
  justify-content: space-between;
}

img {
  width: 10px;
  height: 10px;
  cursor: pointer;
}
</style>
