<script setup>
import SideBar from "@/components/SideBar.vue";

defineProps({
  active: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <SideBar :active="active" :links="treasuryLinks"></SideBar>
</template>

<script>
export default {
  name: "TreasurySideBar",
  data() {
    return {
      treasuryLinks: [
        { href: "/treasury/contributions", title: "Contributions" },
        { href: "/treasury/expenditure", title: "Expenditure" },
        { href: "/treasury/departments", title: "Departments" },
        { href: "/treasury/paybill", title: "Paybill" },
        { href: "/treasury/members", title: "Members" },
        { href: "/treasury/links", title: "Links" },
      ],
    };
  },
};
</script>
