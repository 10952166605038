<script setup>
defineProps({
  church: {
    type: String,
    required: true,
  },
  year: {
    type: Number,
    required: true,
  },
});
</script>
<template>
  <footer>
    <div class="copyright">
      <p>&nbsp;&copy;&nbsp;{{ getYearString() }}&nbsp;{{ church }}</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "BottomBar",
  methods: {
    getYearString() {
      const currentYear = new Date().getFullYear();
      if (this.year === currentYear || this.year > currentYear) {
        return currentYear;
      } else {
        return `${this.year} - ${currentYear}`;
      }
    },
  },
};
</script>

<style scoped>
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--nav-height);
}

footer .copyright {
  height: 100%;
  background-color: var(--color-dark70);
  color: var(--color-light70);
  padding: 0.25rem 1.75rem;
}

footer .copyright p {
  line-height: var(--nav-height);
}
</style>
