<script setup>
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Add a Department">
      <fieldset :disabled="IS_POSTING">
        <div>
          <label class="form-input-holder"
            >Department
            <input v-model.trim="newDepartment" name="name" type="text" />
          </label>
        </div>
        <div class="inline button-group">
          <button
            class="reset-button"
            type="button"
            @click.prevent="resetForm()"
          >
            Reset
          </button>
          <button class="submit-button" type="button" @click.prevent="post()">
            Submit
          </button>
        </div>
      </fieldset>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { putDepartment } from "@/services/PutService";

export default {
  name: "DepartmentsForm",
  data() {
    return {
      newDepartment: null,
    };
  },
  methods: {
    resetForm() {
      this.newDepartment = null;
    },
    post() {
      const OPERATION = "Creation operation";

      if (!this.newDepartment) {
        notifyError(OPERATION, "Invalid department name");
        return;
      }

      const payload = {
        department: this.newDepartment,
      };
      putDepartment(payload)
        .then((data) => {
          if (data.status) {
            notifySuccess(OPERATION, data.message);
            this.resetForm();
          } else {
            notifyError(OPERATION, data.message);
          }
        })
        .catch((error) => notifyError(OPERATION, error));
    },
  },
};
</script>
