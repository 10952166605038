<script setup>
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Useful Links">
      <fieldset :disabled="IS_POSTING"></fieldset>
      <div v-if="entries.length" class="table-holder">
        <table id="report_table">
          <thead>
            <tr>
              <th>#</th>
              <th>Site</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(entry, index) in entries" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <a :href="entry.url" target="_blank">{{ entry.name }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </collapsible-wrapper>
  </form>
</template>

<script>
export default {
  name: "LinksReport",
  data() {
    return {
      entries: [
        { url: "https://sms.jkusdachurch.org", name: "JKUSDA SMS Manager" },
        {
          url: "https://client.airtouch.co.ke:9012/",
          name: "AirTouch SMS Portal",
        },
        {
          url: "https://mail.zoho.com/zm/#mail/folder/inbox",
          name: "Zoho Mail Client",
        },
        {
          url: "https://cloud.digitalocean.com/login",
          name: "DigitalOcean",
        },
        {
          url: "https://my.sendinblue.com/dashboard",
          name: "Sendinblue Dashboard",
        },
        {
          url: "https://fundraisers.jkusdachurch.org",
          name: "JKUSDA Fundraisers",
        },
      ],
    };
  },
};
</script>
