import { createRouter, createWebHistory } from "vue-router";
import { isAuthenticated, isTreasurer, logout } from "@/services/AuthService";
import ContributionPage from "@/views/treasury/ContributionPage.vue";
import DepartmentsPage from "@/views/treasury/DepartmentsPage.vue";
import ExpenditurePage from "@/views/treasury/ExpenditurePage.vue";
import MembersPage from "@/views/treasury/MembersPage.vue";
import DashboardPage from "@/views/DashboardPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import PaybillPage from "@/views/treasury/PaybillPage.vue";
import LinksPage from "@/views/treasury/LinksPage.vue";

const routes = [
  { path: "/", name: "Dashboard", component: DashboardPage },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: { noAuth: true },
  },
  {
    path: "/logout",
    name: "Logout",
    component: LoginPage,
    meta: { noAuth: true },
  },
  {
    path: "/treasury/contributions",
    name: "TrContributions",
    component: ContributionPage,
    alias: "/treasury",
  },
  {
    path: "/treasury/departments",
    name: "TrDepartments",
    component: DepartmentsPage,
  },
  {
    path: "/treasury/expenditure",
    name: "TrExpenditure",
    component: ExpenditurePage,
  },
  { path: "/treasury/members", name: "TrMembers", component: MembersPage },
  { path: "/treasury/paybill", name: "TrPaybill", component: PaybillPage },
  { path: "/treasury/links", name: "TrLinks", component: LinksPage },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: DashboardPage },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition ||
      new Promise((resolve) => {
        setTimeout(() => resolve({ top: 0 }), 300);
      })
    );
  },
});

router.beforeEach((to) => {
  if (to.name === "Logout") {
    logout();
    return { name: "Login" };
  }
  if (to.name === "NotFound") {
    return { name: "Dashboard" };
  }
  if (to.path === "/treasury") {
    return { name: "TrContributions" };
  }
  if (to.path.indexOf("/treasury") === 0) {
    if (!isTreasurer()) {
      console.log("not a treasurer. redirecting");
      return { name: "Dashboard" };
    }
  }
  const requiresAuth = !to.meta || !to.meta.noAuth;
  const notAuthenticated = !isAuthenticated();

  if (requiresAuth && notAuthenticated) {
    return { name: "Login", query: { redirect: to.fullPath } };
  }
});

export default router;
