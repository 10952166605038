<script setup>
import TreasurySideBar from "@/components/treasury/TreasurySidebar.vue";
import MembersForm from "@/components/treasury/members/MembersForm.vue";
import MembersReport from "@/components/treasury/members/MembersReport.vue";
</script>

<template>
  <TreasurySideBar :active="'Members'"></TreasurySideBar>
  <MembersForm v-if="showForm"></MembersForm>
  <MembersReport :class="showForm ? 'subsequent-form' : ''"></MembersReport>
</template>

<script>
import { isCreationTreasurer } from "@/services/AuthService";

export default {
  name: "MembersPage",
  data() {
    return {
      showForm: isCreationTreasurer(),
    };
  },
};
</script>

<style scoped>
.subsequent-form {
  margin-top: 1rem;
}
</style>
