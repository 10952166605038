import { reactive } from "vue";

export const STORE_KEYS = {
  AUTH: "auth_data",
  MEMBERS: "members_data",
  DEPARTMENTS: "departments_data",
  CATEGORIES: "categories_data",
  USERS: "users_data",
  ROLES: "roles_data",
};

export const FETCHED = reactive({
  members: [],
  departments: [],
  categories: [],
  users: [],
  roles: [],
});

export function add(key, data) {
  const array = discriminate(key);
  array.push(data);
  storePersist(key, array);
}

export function setAll(key, many) {
  const array = discriminate(key);
  array.splice(0, array.length);
  for (const arrayKey in many) {
    array.push(many[arrayKey]);
  }
  storePersist(key, array);
}

export function addAll(key, many) {
  const array = discriminate(key);
  for (const arrayKey in many) {
    array.push(many[arrayKey]);
  }
  storePersist(key, array);
}

export function get(key, id) {
  const array = discriminate(key);
  return array.find((item) => item.id === id);
}

export function getAll(key) {
  return discriminate(key);
}

export function update(key, id, data) {
  const array = discriminate(key);
  const index = indexOf(id);
  array[index] = data;
  storePersist(key, array);
}

export function remove(key, id) {
  const array = discriminate(key);
  const index = indexOf(id);
  array.splice(index, 1);
  storePersist(key, array);
}

export function clear() {
  localStorage.clear();
}

export function setAuth(data) {
  storePersist(STORE_KEYS.AUTH, data);
}

export function getAuth() {
  return storeRetrieve(STORE_KEYS.AUTH);
}

export function unsetAuth() {
  storeRemove(STORE_KEYS.AUTH);
}

export function reload() {
  FETCHED.categories = storeRetrieve(STORE_KEYS.CATEGORIES);
  FETCHED.departments = storeRetrieve(STORE_KEYS.DEPARTMENTS);
  FETCHED.members = storeRetrieve(STORE_KEYS.MEMBERS);
  FETCHED.users = storeRetrieve(STORE_KEYS.USERS);
  FETCHED.roles = storeRetrieve(STORE_KEYS.ROLES);
}

function discriminate(key) {
  switch (key) {
    case STORE_KEYS.DEPARTMENTS:
      return FETCHED.departments;
    case STORE_KEYS.CATEGORIES:
      return FETCHED.categories;
    case STORE_KEYS.MEMBERS:
      return FETCHED.members;
    case STORE_KEYS.USERS:
      return FETCHED.users;
    case STORE_KEYS.ROLES:
      return FETCHED.roles;
  }
}

function indexOf(array, id) {
  return array.findIndex((item) => item.id === id);
}

function storePersist(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

function storeRetrieve(key) {
  const dataString = localStorage.getItem(key);
  return dataString ? JSON.parse(dataString) : [];
}

function storeRemove(key) {
  localStorage.removeItem(key);
}
