import { isCommunication, isTreasurer } from "@/services/AuthService";
import { setAll, STORE_KEYS } from "@/services/PersistenceService";
import { commonFetch, ENDPOINTS, METHODS } from "@/services/Caller";

export function loadAll() {
  if (isTreasurer()) {
    return Promise.all([
      fetchMembers(),
      fetchDepartments(),
      fetchCategories(),
      fetchUsers(),
    ]);
  } else if (isCommunication()) {
    return Promise.all([]);
  } else {
    return Promise.all([]);
  }
}

function fetchMembers() {
  return common(ENDPOINTS.MEMBERS_FETCH, { status: 0 }).then((data) =>
    setAll(STORE_KEYS.MEMBERS, data)
  );
}

function fetchDepartments() {
  return common(ENDPOINTS.DEPARTMENTS, { status: 0 }).then((data) => {
    setAll(STORE_KEYS.DEPARTMENTS, data);
  });
}

function fetchCategories() {
  return common(ENDPOINTS.CATEGORIES, { status: 0 }).then((data) =>
    setAll(STORE_KEYS.CATEGORIES, data)
  );
}

function fetchUsers() {
  return common(ENDPOINTS.USERS, { status: 0 }).then((data) =>
    setAll(STORE_KEYS.USERS, data)
  );
}

export function fetchContributions(params) {
  return common(ENDPOINTS.CONTRIBUTIONS, params);
}

export function fetchExpenditures(params) {
  return common(ENDPOINTS.EXPENDITURES, params);
}

export function fetchPendingPaybillValidations(params) {
  return common(ENDPOINTS.PAYBILL_PENDING_VALIDATION, params);
}

function common(url, params) {
  return commonFetch(METHODS.GET, `${url}?${buildParams(params)}`).then(
    (data) => data.data
  );
}

function buildParams(params) {
  if (!params) return "";
  Object.keys(params).forEach((key) => {
    if (params[key] === null) delete params[key];
  });
  return new URLSearchParams(params);
}
