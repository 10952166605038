<script setup>
import { FETCHED } from "@/services/PersistenceService";
import UserActions from "@/components/UserActions.vue";
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Track Expenses">
      <fieldset :disabled="IS_POSTING">
        <div class="filters">
          <label
            >Department
            <select v-model="params.departmentId" name="category">
              <option
                v-for="department in FETCHED.departments"
                :key="department.id"
                :value="department.id"
              >
                {{ department.name }}
              </option>
            </select>
          </label>
          <label
            >Recorded from
            <input
              v-model.trim="params.fromDateCreated"
              name="from"
              type="date"
            />
          </label>
          <label
            >Recorded to
            <input v-model.trim="params.toDateCreated" name="to" type="date" />
          </label>
        </div>
        <div class="inline button-group">
          <button
            class="reset-button"
            type="button"
            @click.prevent="resetForm()"
          >
            Reset
          </button>
          <button
            class="submit-button"
            type="button"
            @click.prevent="queryData()"
          >
            Query
          </button>
        </div>
      </fieldset>
      <div v-if="entries.length" class="table-holder">
        <table id="report_table">
          <thead>
            <tr>
              <th>#</th>
              <th>Disbursed</th>
              <th>Department</th>
              <th>Category</th>
              <th>Purpose</th>
              <th>Type</th>
              <th>Treasurer</th>
              <th>Amount</th>
              <th>Recorded</th>
              <th v-if="canEdit || canDelete">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(entry, index) in entries" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ entry.date_disbursed }}</td>
              <td>{{ entry.department }}</td>
              <td>{{ entry.contribution }}</td>
              <td>{{ entry.purpose }}</td>
              <td>{{ entry.dependent_type }}</td>
              <td>{{ entry.posted_by }}</td>
              <td>{{ entry.amount }}</td>
              <td>{{ entry.date_created }}</td>
              <td v-if="canEdit || canDelete">
                <UserActions
                  :can-delete="canDelete"
                  :can-edit="canEdit"
                ></UserActions>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Total</td>
              <td>{{ total }}</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { fetchExpenditures } from "@/services/FetchService";

export default {
  name: "ExpenditureReport",
  data() {
    return {
      canEdit: false,
      canDelete: false,
      params: {
        departmentId: null,
        source: null,
        creatorId: null,
        fromDateDisbursed: null,
        toDateDisbursed: null,
        fromDateCreated: null,
        toDateCreated: null,
      },
      entries: [],
      total: null,
    };
  },
  methods: {
    queryData() {
      const params = {
        status: 1,
      };
      if (this.params.departmentId) {
        params.department_id = this.params.departmentId;
      }
      if (this.params.fromDateCreated) {
        params.from = this.params.fromDateCreated;
      }
      if (this.params.toDateCreated) {
        params.to = this.params.toDateCreated;
      }
      fetchExpenditures(params)
        .then((x) => {
          notifySuccess("Fetch operation", "Expenses have been fetched");

          const currencyFormatter = new Intl.NumberFormat("en-KE", {
            style: "currency",
            currency: "KES",
          });
          const sum = x.reduce((result, entry) => result + entry.amount, 0);
          this.entries = x.map((entry) => {
            entry.amount = currencyFormatter.format(entry.amount);
            entry.source = entry.source === "D" ? "Church" : "Self";
            return entry;
          });
          this.total = currencyFormatter.format(sum);
        })
        .catch((error) => notifyError("Fetch operation", error));
    },
    resetForm() {
      this.entries = [];
      this.params = {
        departmentId: null,
        source: null,
        creatorId: null,
        fromDateDisbursed: null,
        toDateDisbursed: null,
        fromDateCreated: null,
        toDateCreated: null,
      };
    },
  },
};
</script>
