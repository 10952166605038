import { reactive } from "vue";
import { getAuth, setAuth, unsetAuth } from "@/services/PersistenceService";
import { commonFetch, ENDPOINTS, METHODS } from "@/services/Caller";

export const AUTH = reactive({
  isLoggedIn: isAuthenticated(),
});

const ROLES = {
  ADMINISTRATOR: "ADMINISTRATOR",
  AUDITOR: "AUDITOR",
  SUPERUSER: "Dev",
  COMMUNICATION: "COMMUNICATION",
  TREASURER: "Treasurer",
  ASSISTANT_TREASURER: "ASSISTANT_TREASURER",
  CLERK: "CLERK",
};

export function authUser(username, password) {
  const encoded = btoa(`${username}:${password}`);

  return commonFetch(METHODS.GET, ENDPOINTS.LOGIN, null, encoded, false).then(
    (payload) => {
      if (null != payload.data) {
        const data = payload.data;
        setAuth({
          roles: data.user_type,
          dateCreated: data.creation,
          expiry: new Date(new Date().getTime() + 1000 * 3600),
          name: data.user_name,
          phone: data.user_phone,
          email: data.user_email,
          token: data.api_key,
          id: data.user_id,
        });
        AUTH.isLoggedIn = true;
      }
    }
  );
}

export function isAuthenticated() {
  const auth = getAuth();
  if (auth) {
    const expiry = new Date(auth.expiry);
    const nowPlus5Minutes = new Date(new Date().getTime() + 1000 * 300);
    return expiry >= nowPlus5Minutes;
  } else {
    return false;
  }
}

export function getToken() {
  return isAuthenticated() ? getAuth().token : null;
}

export function logout() {
  unsetAuth();
  AUTH.isLoggedIn = false;
}

export function isTreasurer() {
  return checkRoles([ROLES.TREASURER, ROLES.SUPERUSER]);
}

export function isCreationTreasurer() {
  return checkRoles([
    ROLES.TREASURER,
    ROLES.ASSISTANT_TREASURER,
    ROLES.SUPERUSER,
  ]);
}

export function isEditingTreasurer() {
  return checkRoles([ROLES.TREASURER, ROLES.SUPERUSER]);
}

export function isDeletingTreasurer() {
  return checkRoles([ROLES.TREASURER, ROLES.SUPERUSER]);
}

export function isCommunication() {
  return checkRoles([ROLES.COMMUNICATION, ROLES.AUDITOR, ROLES.SUPERUSER]);
}

export function isAdministrator() {
  return checkRoles([ROLES.ADMINISTRATOR, ROLES.AUDITOR, ROLES.SUPERUSER]);
}

function getCurrentRoles() {
  return isAuthenticated() ? getAuth().roles : null;
}

function checkRoles(staticRoles) {
  const dynamicRoles = getCurrentRoles();
  return dynamicRoles && intersection(staticRoles, dynamicRoles);
}

function intersection(staticRoles, dynamicRoles) {
  return staticRoles.filter((role) => dynamicRoles.includes(role)).length > 0;
}
