<script setup>
import DepartmentsForm from "@/components/treasury/departments/DepartmentsForm.vue";
import DepartmentsReport from "@/components/treasury/departments/DepartmentsReport.vue";
import TreasurySideBar from "@/components/treasury/TreasurySidebar.vue";
import CategoriesForm from "@/components/treasury/departments/CategoriesForm.vue";
import CategoriesReport from "@/components/treasury/departments/CategoriesReport.vue";
</script>

<template>
  <TreasurySideBar :active="'Departments'"></TreasurySideBar>
  <DepartmentsForm v-if="showForm"></DepartmentsForm>
  <DepartmentsReport
    :class="showForm ? 'subsequent-form' : ''"
  ></DepartmentsReport>
  <CategoriesForm
    v-if="showForm"
    :class="showForm ? 'subsequent-form' : ''"
  ></CategoriesForm>
  <CategoriesReport
    :class="showForm ? 'subsequent-form' : ''"
  ></CategoriesReport>
</template>

<script>
import { isCreationTreasurer } from "@/services/AuthService";

export default {
  name: "DepartmentsPage",
  data() {
    return {
      showForm: isCreationTreasurer(),
    };
  },
};
</script>

<style scoped>
.subsequent-form {
  margin-top: 1rem;
}
</style>
