<script setup>
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Track Members">
      <fieldset :disabled="IS_POSTING">
        <div class="inline button-group">
          <button
            class="reset-button"
            type="button"
            @click.prevent="resetForm()"
          >
            Reset
          </button>
          <button
            class="submit-button"
            type="button"
            @click.prevent="queryData()"
          >
            Query
          </button>
        </div>
      </fieldset>
      <div v-if="entries.length" class="table-holder">
        <table id="report_table">
          <thead>
            <tr>
              <th>#</th>
              <th>Details</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(entry, index) in entries" :key="index">
              <td>{{ index + 1 }}</td>
              <td
                v-html="
                  [entry.name].concat(entry.emails, entry.phones).join('<br/>')
                "
              ></td>
              <td>{{ entry.date_created }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { FETCHED } from "@/services/PersistenceService";

export default {
  name: "MembersReport",
  data() {
    return {
      canEdit: false,
      canDelete: false,
      entries: [],
    };
  },
  methods: {
    queryData() {
      this.entries = FETCHED.members;
    },
    resetForm() {
      this.entries = [];
    },
  },
};
</script>
